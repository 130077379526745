import {
  Phase,
  PhaseData,
  MultiplePhaseData,
  Individual,
  IndividualData,
  Statistic,
} from "../domain/entity";
import { Setting } from "../app-setting";
export class SpreadSheetInterface {
  private phaseSheetIds: string[];
  private phaseNames: string[];
  private phaseSheetTitles: string[] | undefined;
  private resultSheetId: string | undefined;
  private gid: string;
  private sid: string;

  constructor(sid: string, gid: string, setting: Setting) {
    const school = setting.schools.find((item) => item.sid === sid);
    this.sid = sid;
    if (school === undefined) throw new Error(`Cannot find ${sid}.`);
    const group = school.groups.find((item) => item.gid === gid);
    if (group === undefined) throw new Error(`Cannot find ${gid}.`);
    this.gid = group.gid;
    this.phaseSheetIds = group.phase.map((phase) => phase.sheetId);
    this.phaseNames = group.phase.map((phase) => phase.name);
    this.phaseSheetTitles = undefined;
    this.resultSheetId = group.resultSheetId;
  }

  async initPhaseData() {
    try {
      this.phaseSheetTitles = await Promise.all(
        this.phaseSheetIds.map(async (id) => {
          // @ts-ignore
          const sheets = await window.gapi.client.sheets.spreadsheets.get({
            spreadsheetId: id,
          });
          // Get default sheet
          return sheets.result.sheets[0].properties.title as string;
        })
      );
    } catch (e) {
      throw new Error(`Cannot initialize, ${e}`);
    }
  }

  async getPhaseData(pid: number): Promise<PhaseData> {
    if (this.phaseSheetTitles === undefined) {
      throw new Error("PhaseSheet is not initialized.");
    }
    // @ts-ignore
    const response = await window.gapi.client.sheets.spreadsheets.values.get({
      spreadsheetId: this.phaseSheetIds[pid],
      range: this.phaseSheetTitles[pid] + "!R2C1:C1000",
    });
    const phases = new PhaseData(
      response.result.values.map((row: any) => new Phase(row))
    );

    return phases;
  }

  async getAllPhaseData(): Promise<MultiplePhaseData> {
    if (this.phaseSheetTitles === undefined) {
      throw new Error("PhaseSheet is not initialized.");
    }
    return new MultiplePhaseData(
      await Promise.all(
        this.phaseSheetIds.map((item, i) => this.getPhaseData(i))
      )
    );
  }

  async getStatisticData(): Promise<Statistic> {
    if (this.resultSheetId === undefined) {
      throw new Error("resultSheetId is undefined.");
    }
    const response =
      // @ts-ignore
      await window.gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: this.resultSheetId,
        range: `${this.gid}.statistic!R1C1:R1C1000`,
      });
    return new Statistic(response.result.values[0]);
  }

  async clearIndividualSheet() {
    // @ts-ignore
    await window.gapi.client.sheets.spreadsheets.values.clear({
      spreadsheetId: this.resultSheetId,
      range: `${this.gid}.individual!R1C1:C1000`,
    });
  }

  async clearStatisticSheet() {
    // @ts-ignore
    await window.gapi.client.sheets.spreadsheets.values.clear({
      spreadsheetId: this.resultSheetId,
      range: `${this.gid}.statistic!R1C1:C1000`,
    });
  }

  async appendIndividualData(data: IndividualData) {
    const { uids, allField } = data.getUidsAndAllField();
    // @ts-ignore
    await window.gapi.client.sheets.spreadsheets.values.append({
      spreadsheetId: this.resultSheetId,
      range: `${this.gid}.individual!R1C1`,
      valueInputOption: "USER_ENTERED",
      insertDataOption: "INSERT_ROWS",
      resource: {
        values: uids.map((uid, i) => [uid, ...allField[i]]),
      },
    });
  }

  async appendStatisticData(data: Statistic) {
    // @ts-ignore
    await window.gapi.client.sheets.spreadsheets.values.append({
      spreadsheetId: this.resultSheetId,
      range: `${this.gid}.statistic!R1C1`,
      valueInputOption: "USER_ENTERED",
      insertDataOption: "INSERT_ROWS",
      resource: {
        values: [data.getAllField()],
      },
    });
  }

  async loadIndividualData(): Promise<IndividualData> {
    if (this.resultSheetId === undefined) {
      throw new Error("resultSheetId is undefined.");
    }
    const response =
      // @ts-ignore
      await window.gapi.client.sheets.spreadsheets.values.get({
        spreadsheetId: this.resultSheetId,
        range: `${this.gid}.individual!R1C1:C1000`,
      });
    const individualData = new IndividualData(
      response.result.values.map((row: any) => new Individual(row))
    );
    return individualData;
  }

  getPhaseSheetUrls() {
    return this.phaseSheetIds.map(
      (id) => `https://docs.google.com/spreadsheets/d/${id}`
    );
  }
  getPhaseNames() {
    return this.phaseNames;
  }

  getResultSheetUrl() {
    return `https://docs.google.com/spreadsheets/d/${this.resultSheetId}`;
  }

  getSid() {
    return this.sid;
  }
}
