export class frequency {
  static summarize(column: string[], max: number) {
    const freqlist = [...Array(max)].map((_, i) => 0);
    column.forEach((item) => {
      const score = Number(item);
      if (score > 0) freqlist[score - 1]++;
    });
    return freqlist.join(",");
  }

  static extract(data: string) {
    const items = data.split(",").map((item) => Number(item));
    return items;
  }

  static toPercentile(items: number[]) {
    const sum = items.reduce((sum, next) => sum + next, 0);
    return items.map((item) => (100.0 * item) / sum);
  }

  static getPositionInPercentile(item: number, items: number[]) {
    const pos =
      items.reduce((res, next, i) => (i < item - 1 ? res + next : res), 0) +
      items[item - 1] / 2;
    const sum = items.reduce((sum, next) => sum + next, 0);
    return (100.0 * pos) / sum;
  }
}
