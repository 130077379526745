import { Row, Col } from "react-bootstrap";
import { RaderChart, BarChartHorizontal, BandChart } from "./plotlyGraph";
import { Individual, Statistic } from "../domain/entity";
import { frequency } from "../domain/frequency";

type Props = {
  individual: Individual;
  statistic: Statistic;
  phaseNames: string[];
  sid: string;
};

export default function IndividualLayout({
  individual,
  statistic,
  phaseNames,
  sid,
}: Props) {
  const ind = individual.getAllField();
  const st = statistic.getAllField();
  if (sid === "kakogawahigashi") {
    return (
      <>
        <Row className="mt-5">
          <Col xs={12} xl={6}>
            <h3>加古川東高校への志望度と満足度</h3>
            <RaderChart
              labels={[
                "加古川東高校への<br>志願度",
                "加古川東高校への<br>満足度",
                "加古川東高校の<br>生徒であることが<br>誇らしい",
                "加古川東高校に<br>愛着をもっている",
              ]}
              averageValues={st.slice(1, 5)}
              individualValues={[ind.slice(1, 5)]}
              phaseNames={phaseNames}
              range={[0, 4]}
              width={6}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={12} xl={6}>
            <h3>アクティブラーニング</h3>
            <BarChartHorizontal
              labels={["AL得意さ", "AL参加経験", "AL外化"]}
              averageValues={st.slice(5, 7)}
              individualValues={[ind.slice(5, 7)]}
              phaseNames={phaseNames}
              range={[0, 5]}
              width={6}
              height={200}
            />
          </Col>
          <Col xs={12} xl={6}>
            <h3>中学のときに力をいれたこと</h3>
            <RaderChart
              labels={[
                "授業内容に<br>ついて深く学ぶ",
                "授業内容に限らず<br>幅広い知識や<br>教養を身につける",
                "友人をつくる・<br>人間関係を広げる",
                "課外活動（部活動・<br>同好会及び学校外での<br>スポーツクラブ等）",
                "課外活動<br>（ボランティア）",
                "塾や通信教育など<br>受験にかかわる活動",
              ]}
              averageValues={st.slice(7, 13)}
              individualValues={[ind.slice(7, 13)]}
              phaseNames={phaseNames}
              range={[0, 4]}
              width={6}
            />
          </Col>
          <Col xs={12} xl={6}>
            <h3>学習への向き合い方</h3>
            <BarChartHorizontal
              labels={[
                "勉強のやり方が、自分に合っているか<br>どうかを考えながら勉強する",
                "勉強でわからないところがあったら、<br>勉強のやり方をいろいろ変えてみる",
                "勉強しているときに、やった内容を<br>覚えているかどうかを確かめる",
                "勉強するときは、最初に<br>計画を立ててから始めている",
                "勉強するときは、<br>自分で決めた計画にそっておこなう",
                "勉強する前に、これから何をどうやって<br>勉強するかを考える",
                "自分が用いる勉強方法がどのようなときに<br>最も効果的なのか、よくわかっている",
                "自分が何が得意で<br>何が不得意かをわかっている",
              ]}
              averageValues={st.slice(16, 24)}
              individualValues={[ind.slice(16, 24)]}
              phaseNames={phaseNames}
              range={[0, 6]}
              width={6}
            />
          </Col>
          <Col xs={12} xl={6}>
            <h3>一週間の学習時間</h3>
            <BandChart
              labels={[
                "21時間以上",
                "16〜20時間",
                "11〜15時間",
                "6〜10時間",
                "3〜5時間",
                "1〜2時間",
                "1時間未満",
              ]}
              averageValues={frequency.extract(st[13])}
              individualValues={[ind[13]]}
              phaseNames={phaseNames}
              width={6}
              height={200}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={12} xl={6}>
            <h3>かことんポリシー到達度</h3>
            <RaderChart
              labels={[
                "基本的知識",
                "情報収集力",
                "課題解決力",
                "思いやり",
                "楽観力",
                "責任感",
                "好奇心",
                "関与力",
                "自走力",
              ]}
              averageValues={st.slice(24, 33)}
              individualValues={[ind.slice(24, 33)]}
              phaseNames={phaseNames}
              range={[0, 3]}
              width={6}
            />
          </Col>
          <Col xs={12} xl={6}>
            <h3>資質・能力の獲得感</h3>
            <BarChartHorizontal
              labels={[
                "計画や目標を立てて日々を過ごすことができる",
                "社会の問題に対して分析したり<br>考えたりすることができる",
                "リーダーシップをとることができる",
                "図書館やインターネットを利用して必要な情報を得たり<br>わからないことを調べたりすることができる",
                "他の人と議論することができる",
                "自分の言葉で文章を書くことができる",
                "人前で発表をすることができる",
                "他の人と協力して物事に取り組める",
                "コンピュータやインターネットを<br>操作することができる",
                "時間を有効に使うことができる",
                "新しいアイディアを得たり<br>発見したりすることができる",
                "困難なことでもチャレンジすることができる",
                "人の話を聞くことができる",
                "自分とは異なる意見や価値を<br>尊重することができる",
                "人に対して思いやりを持つことができる",
                "忍耐強く物事に取り組むことができる",
                "異文化や世界に関心を持つことができる",
                "自分を客観的に理解することができる",
              ]}
              averageValues={st.slice(46, 64)}
              individualValues={[ind.slice(46, 64)]}
              phaseNames={phaseNames}
              range={[0, 5]}
              width={6}
              height={500}
            />
          </Col>
          <Col xs={12} xl={6}>
            <h3>マインドセット</h3>
            <RaderChart
              labels={[
                "自分の才能は、<br>生まれながらに<br>決まったものではなく、<br>伸ばすことができる。",
                "私の考えでは、<br>才能というものは<br>変えることが<br>できるものだと思う。",
                "学習によって、<br>新しいことがらを<br>学ぶだけでなく、<br>才能も変えていくことができる。",
              ]}
              averageValues={st.slice(43, 46)}
              individualValues={[ind.slice(43, 46)]}
              phaseNames={phaseNames}
              range={[0, 6]}
              width={6}
            />
          </Col>
          <Col xs={12} xl={6}>
            <h3>パーソナリティ</h3>
            <RaderChart
              labels={["外向性", "協調性", "勤勉性", "神経質", "開放性"]}
              averageValues={st.slice(68, 73)}
              individualValues={[ind.slice(68, 73)]}
              phaseNames={phaseNames}
              range={[0, 7]}
              width={6}
            />
          </Col>

          <Col xs={12} xl={6} className="mt-5">
            <h3>就職や将来の生き方を考える機会</h3>
            <h4>就職や将来を考える機会</h4>
            <BandChart
              labels={[
                "まったく得られなかった",
                "少し得られた",
                "まあまあ得られた",
                "かなり得られた",
              ]}
              averageValues={frequency.extract(st[14])}
              individualValues={[ind[14]]}
              phaseNames={phaseNames}
              width={6}
            />
            <h4>その機会が与えた影響</h4>
            <BandChart
              labels={[
                "まったく影響を及ぼしていない",
                "あまり影響を及ぼしていない",
                "どちらとも言えない",
                "まあまあ影響を及ぼしている",
                "かなり影響を及ぼしている",
              ]}
              averageValues={frequency.extract(st[15])}
              individualValues={[ind[15]]}
              phaseNames={phaseNames}
              width={6}
            />

            <h3>将来の見通しとその実行</h3>
            <BandChart
              labels={[
                "将来の見通しを持っていない",
                "何をすべきかはまだ分からない",
                "何をすべきかは分かっているが、<br>実行はできていない",
                "何をすべきか分かっているし、<br>実行もしている",
              ]}
              averageValues={frequency.extract(st[73])}
              individualValues={[ind[73]]}
              phaseNames={phaseNames}
              width={6}
            />
          </Col>
          <Col lg={12} xl={6} className="mt-5">
            <h3>進学を希望する大学や学部名</h3>
            <div className="text">{individual.getField(65)}</div>
            <h3 className="mt-5">
              3年後卒業するときに、どのような18歳になっていたいか
            </h3>
            <div className="text">{individual.getField(67)}</div>
          </Col>
        </Row>
      </>
    );
  } else if (sid === "johoku") {
    return (
      <>
        <Row className="mt-5">
          <Col xs={12} xl={6}>
            <h3>城北高校への志望度と満足度</h3>
            <RaderChart
              labels={[
                "城北高校への<br>志願度",
                "城北高校への<br>満足度",
                "城北高校の<br>生徒であることが<br>誇らしい",
              ]}
              averageValues={st.slice(1, 4)}
              individualValues={[ind.slice(1, 4)]}
              phaseNames={phaseNames}
              range={[0, 4]}
              width={6}
            />
          </Col>
          <Col xs={12} xl={6}>
            <h3>城北高校マイスターマイルストーン</h3>
            <BarChartHorizontal
              labels={[
                "M1 メタ認知する力",
                "M2 統合する力",
                "M3 調整する力",
                "M4 思考する力",
                "M5 発信する力",
                "M6 受容する力",
              ]}
              averageValues={st.slice(44, 50)}
              individualValues={[ind.slice(44, 50)]}
              phaseNames={phaseNames}
              range={[0, 6]}
              width={6}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={12} xl={6}>
            <h3>アクティブラーニング</h3>
            <BarChartHorizontal
              labels={["AL得意さ", "AL参加経験", "AL外化"]}
              averageValues={st.slice(4, 6)}
              individualValues={[ind.slice(4, 6)]}
              phaseNames={phaseNames}
              range={[0, 5]}
              width={6}
              height={200}
            />
          </Col>
          <Col xs={12} xl={6}>
            <h3>中学のときに力をいれたこと</h3>
            <RaderChart
              labels={[
                "授業内容に<br>ついて深く学ぶ",
                "授業内容に限らず<br>幅広い知識や<br>教養を身につける",
                "友人をつくる・<br>人間関係を広げる",
                "課外活動（部活動・<br>同好会及び学校外での<br>スポーツクラブ等）",
                "課外活動<br>（ボランティア）",
                "塾や通信教育など<br>受験にかかわる活動",
              ]}
              averageValues={st.slice(5, 11)}
              individualValues={[ind.slice(5, 11)]}
              phaseNames={phaseNames}
              range={[0, 4]}
              width={6}
            />
          </Col>
          <Col xs={12} xl={6}>
            <h3>
              中学での進路指導などで
              <br />
              就職・将来について考えた機会
            </h3>
            <BandChart
              labels={[
                "まったくなかった",
                "少しあった",
                "まあまああった",
                "かなりあった",
              ]}
              averageValues={frequency.extract(st[14])}
              individualValues={[ind[14]]}
              phaseNames={phaseNames}
              width={6}
              height={200}
            />
          </Col>
          <Col xs={12} xl={6}>
            <h3>
              中学での進路指導などは
              <br />
              どの程度影響を及ぼしているか
            </h3>
            <BandChart
              labels={[
                "まったく影響を及ぼしていない",
                "あまり影響を及ぼしていない",
                "どちらとも言えない",
                "まあまあ影響を及ぼしている",
                "かなり影響を及ぼしている",
              ]}
              averageValues={frequency.extract(st[15])}
              individualValues={[ind[15]]}
              phaseNames={phaseNames}
              width={6}
              height={200}
            />
          </Col>
          <Col xs={12} xl={6}>
            <h3>一週間の学習時間</h3>
            <BandChart
              labels={[
                "29時間以上",
                "22〜28時間",
                "15〜21時間",
                "11〜14時間",
                "6〜10時間",
                "3〜5時間",
                "3時間未満",
              ]}
              averageValues={frequency.extract(st[11])}
              individualValues={[ind[11]]}
              phaseNames={phaseNames}
              width={6}
              height={200}
            />
          </Col>
          <Col xs={12} xl={6}>
            <h3>スマートフォンで遊んでいる時間</h3>
            <BandChart
              labels={[
                "29時間以上",
                "22〜28時間",
                "15〜21時間",
                "11〜14時間",
                "6〜10時間",
                "3〜5時間",
                "3時間未満",
              ]}
              averageValues={frequency.extract(st[12])}
              individualValues={[ind[12]]}
              phaseNames={phaseNames}
              width={6}
              height={200}
            />
          </Col>
          <Col xs={12} xl={6}>
            <h3>スマートフォンで学習している時間</h3>
            <BandChart
              labels={[
                "29時間以上",
                "22〜28時間",
                "15〜21時間",
                "11〜14時間",
                "6〜10時間",
                "3〜5時間",
                "3時間未満",
              ]}
              averageValues={frequency.extract(st[13])}
              individualValues={[ind[13]]}
              phaseNames={phaseNames}
              width={6}
              height={200}
            />
          </Col>
          <Col xs={12} xl={6}>
            <h3>学習への向き合い方</h3>
            <BarChartHorizontal
              labels={[
                "勉強のやり方が、自分に合っているか<br>どうかを考えながら勉強する",
                "勉強でわからないところがあったら、<br>勉強のやり方をいろいろ変えてみる",
                "勉強しているときに、やった内容を<br>覚えているかどうかを確かめる",
                "勉強するときは、最初に<br>計画を立ててから始めている",
                "勉強するときは、<br>自分で決めた計画にそっておこなう",
                "勉強する前に、これから何をどうやって<br>勉強するかを考える",
                "自分が用いる勉強方法がどのようなときに<br>最も効果的なのか、よくわかっている",
                "自分が何が得意で<br>何が不得意かをわかっている",
              ]}
              averageValues={st.slice(16, 24)}
              individualValues={[ind.slice(16, 24)]}
              phaseNames={phaseNames}
              range={[0, 6]}
              width={6}
            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={12} xl={6}>
            <h3>探究活動における学習行動</h3>
            <BarChartHorizontal
              labels={[
                "学習は自分の将来に役に立つと思っている",
                "学習を通して，自分の知識を増やし，<br>能力の向上につなげたい",
                "学習に対する自分の考え・意見をもっている",
                "新しいことを学ぶ時，自分がどれぐらいの<br>時間をかければその新しいことを理解できるのかを考える",
                "探求活動をする時には自分で目標を立てる",
                "探求活動時には，自分がどこまで立てた目標を<br>達成できているのか確認しながら学習を進める",
                "よく分かっているところとそうでないところを<br>探しながら学習する",
                "探求活動をしているときに，方向性が<br>間違っていないかをたしかめる",
                "課題に取り組むとき，解決するために<br>必要な知識や方法を考える",
                "課題に取り組むとき，これまでの経験や<br>授業で得た知識を思い出してから問題を解く",
                "授業で得た知識を使って，新しい課題に<br>取り組むことができる",
                "勉強したことの背景を考え，なぜ<br>そのようなことが起きたのかを考える",
                "新しいことを学ぶとき，以前学習したことの<br>似ている点を考えながら進める",
                "得た知識を自分の言葉で置き換える",
                "新しいことを学習するときには，<br>その内容が以前勉強したことと比べて何が新しいのか考えながらすすめる",
                "学習したことを日常生活の様々な<br>場面に当てはめてみる",
                "以前学習したことと，新しく得た<br>知識や経験とのつながりを考える",
                "理解したことを図や絵で表現する",
                "課題に取り組むとき，問題文を正しく<br>読めているかどうか確認する",
                "学んだことを自分の言葉で説明<br>できるようにする",
              ]}
              averageValues={st.slice(24, 44)}
              individualValues={[ind.slice(24, 44)]}
              phaseNames={phaseNames}
              range={[0, 5]}
              width={6}
              height={550}
            />
          </Col>
          <Col xs={12} xl={6}>
            <h3>資質・能力の獲得感</h3>
            <BarChartHorizontal
              labels={[
                "計画や目標を立てて日々を過ごすことができる",
                "社会の問題に対して分析したり<br>考えたりすることができる",
                "リーダーシップをとることができる",
                "図書館やインターネットを利用して必要な情報を得たり<br>わからないことを調べたりすることができる",
                "他の人と議論することができる",
                "自分の言葉で文章を書くことができる",
                "人前で発表をすることができる",
                "他の人と協力して物事に取り組める",
                "コンピュータやインターネットを<br>操作することができる",
                "時間を有効に使うことができる",
                "新しいアイディアを得たり<br>発見したりすることができる",
                "困難なことでもチャレンジすることができる",
                "人の話を聞くことができる",
                "自分とは異なる意見や価値を<br>尊重することができる",
                "人に対して思いやりを持つことができる",
                "忍耐強く物事に取り組むことができる",
                "異文化や世界に関心を持つことができる",
                "自分を客観的に理解することができる",
              ]}
              averageValues={st.slice(68, 86)}
              individualValues={[ind.slice(68, 86)]}
              phaseNames={phaseNames}
              range={[0, 5]}
              width={6}
              height={500}
            />
          </Col>
        </Row>
        <Row>
          <h3>パーソナリティ</h3>
          <Col xs={12} xl={6}>
            <RaderChart
              labels={["外向性", "協調性", "勤勉性", "神経質", "開放性"]}
              averageValues={st.slice(90, 95)}
              individualValues={[ind.slice(90, 95)]}
              phaseNames={phaseNames}
              range={[0, 7]}
              width={6}
            />
          </Col>
          <Col xs={12} xl={6}>
            <BarChartHorizontal
              labels={[
                "素直に他人の意見を聞き，取り入れていると思う",
                "自分の考えを持ち，貫く方だと思う。",
                "礼儀正しく振る舞っていると思う。",
                "学校行事や部活動に積極的に<br>取り組んでいると思う。",
              ]}
              averageValues={st.slice(60, 64)}
              individualValues={[ind.slice(60, 64)]}
              phaseNames={phaseNames}
              range={[0, 7]}
              width={6}
              height={200}
            />
            <BarChartHorizontal
              labels={[
                "自分の才能は，生まれながらに<br>決まったものではなく，伸ばすことができる。",
                "私の考えでは，才能というものは<br>変えることができるものだと思う。",
                "学習によって，新しいことがらを<br>学ぶだけでなく，才能も変えていくことができる。",
                "才能というものは自分一人では変えられないが，<br>出会いや他者からの影響によって，<br>変えることができるものだと思う。",
              ]}
              averageValues={st.slice(64, 68)}
              individualValues={[ind.slice(64, 68)]}
              phaseNames={phaseNames}
              range={[0, 6]}
              width={6}
              height={200}
            />
          </Col>

          <Col xs={12} xl={6} className="mt-5">
            <h3>将来の見通しとその実行</h3>
            <BandChart
              labels={[
                "将来の見通しを持っていない",
                "何をすべきかはまだ分からない",
                "何をすべきかは分かっているが、実行はできていない",
                "何をすべきか分かっているし、実行もしている",
              ]}
              averageValues={frequency.extract(st[95])}
              individualValues={[ind[95]]}
              phaseNames={phaseNames}
              width={6}
              height={220}
            />
          </Col>
          <Col lg={12} xl={6} className="mt-5">
            <h3>進学を希望する大学や学部名・専門</h3>
            <BandChart
              labels={["ある", "ない"]}
              averageValues={frequency.extract(st[88])}
              individualValues={[ind[88]]}
              phaseNames={phaseNames}
              width={6}
            />
            <h3>理由</h3>
            <div className="text">{individual.getField(89)}</div>
          </Col>
        </Row>
      </>
    );
  } else {
    throw Error("存在しないsidです。");
  }
}
