import Plot from "react-plotly.js";
import { frequency } from "../domain/frequency";
const containerWidth = [
  0, 100, 100, 220, 320, 420, 520, 600, 700, 800, 900, 1000, 1100,
];
const containerHeight = 300;
const containerHeightBandChart = 170;

// const legendInner = {
//   x: 1,
//   xanchor: "right",
//   y: 1,
// };

// const legendBottom = {
//   orientation: "h",
// };

const font = {
  size: 10,
};

const colors = [
  "#F544C6",
  "#65F549",
  "#4EDDF5",
  "#F5DC3B",
  "#F5A96C",
  "#F544C6",
  "#65F549",
  "#4EDDF5",
  "#F5DC3B",
  "#F5A96C",
];

const colorsAverage = [
  "#8BC4F0",
  "#73D991",
  "#FCFA86",
  "#FCE086",
  "#FAB387",
  "#FA948D",
  "#AA73D9",
  "#7EC5E0",
  "#86F5E2",
  "#6FDE98",
  "#8DFF80",
  "#68A3AD",
];

type RadarChartProps = {
  labels: string[];
  averageValues: number[] | string[];
  individualValues?: number[][] | string[][];
  phaseNames?: string[];
  range: number[];
  width: number;
};

export function RaderChart({
  labels,
  averageValues,
  individualValues,
  phaseNames,
  range,
  width,
}: RadarChartProps) {
  // Define layout
  const layout = {
    polar: {
      radialaxis: {
        visible: true,
        range: range,
        dtick: 1,
      },
    },
    width: containerWidth[width],
    height: containerHeight,
    margin: { t: 50, l: 80, r: 20, b: 50 },
    font,
  };

  // Average line
  const average = {
    type: "scatterpolar",
    theta: [...labels, labels[0]],
    r: [...averageValues, averageValues[0]],
    fill: "toself",
    mode: "none",
    name: "平均",
  };

  if (individualValues === undefined || phaseNames === undefined) {
    // plot average line only
    return (
      <Plot
        data={[average as any]}
        layout={layout}
        config={{ displayModeBar: false }}
      />
    );
  } else {
    // plot individual score with average line
    const sc = individualValues?.map((values, i) => ({
      type: "scatterpolar",
      theta: [...labels, labels[0]],
      r: [...values, values[0]],
      name: phaseNames?.[i],
      marker: {
        color: colors[i],
      },
    })) as any;

    return (
      <Plot
        data={[average, ...sc]}
        layout={layout}
        config={{ displayModeBar: false }}
      />
    );
  }
}

type BarChartProps = {
  labels: string[];
  averageValues: number[] | string[];
  individualValues?: number[][] | string[][];
  phaseNames?: string[];
  range: number[];
  width: number;
  height?: number;
};

export function BarChart(props: BarChartProps) {
  // Define layout
  const layout = {
    width: containerWidth[props.width],
    height: props.height ?? containerHeight,
    margin: { t: 20, l: 10, r: 70, b: 200 },
    // legend: legendInner,
    legend: { orientation: "v", yanchor: "top" },

    font,
    yaxis: {
      dtick: 1,
      range: [props.range[0], props.range[1] + 0.2],
    },
    xaxis: { tickangle: 90 },
  } as any;

  // Average line
  const average = {
    type: "scatter",
    name: "平均",
    x: props.labels,
    y: props.averageValues,
    mode: "lines+markers",
    marker: {
      color: colorsAverage[0],
    },
  };

  if (props.individualValues === undefined || props.phaseNames === undefined) {
    // plot average line only
    return (
      <Plot
        data={[average as any]}
        layout={layout}
        config={{ displayModeBar: false }}
      />
    );
  } else {
    // plot individual score with average line
    const sc = props.individualValues.map((value, i) => ({
      type: "bar",
      x: props.labels,
      y: value,
      name: props.phaseNames?.[i],
      marker: {
        color: colors[i],
      },
    })) as any;

    return (
      <Plot
        data={[average, ...sc]}
        layout={layout}
        config={{ displayModeBar: false }}
      />
    );
  }
}

export function BarChartHorizontal(props: BarChartProps) {
  // Define layout
  const layout = {
    width: containerWidth[props.width],
    height: props.height ?? containerHeight,
    margin: { t: 20, l: 250, r: 50, b: 20 },
    // legend: legendInner,
    legend: { orientation: "v", yanchor: "top" },

    font,
    xaxis: {
      dtick: 1,
      range: [props.range[0], props.range[1] + 0.2],
    },
    yaxis: {
      autorange: "reversed",
    },
  } as any;

  // Average line
  const average = {
    type: "scatter",
    name: "平均",
    x: props.averageValues,
    y: props.labels,
    mode: "lines+markers",
    marker: {
      color: colorsAverage[0],
    },
  };

  if (props.individualValues === undefined || props.phaseNames === undefined) {
    // plot average line only
    return (
      <Plot
        data={[average as any]}
        layout={layout}
        config={{ displayModeBar: false }}
      />
    );
  } else {
    // plot individual score with average line
    const sc = props.individualValues.map((value, i) => ({
      type: "bar",
      x: value,
      y: props.labels,
      name: props.phaseNames?.[i],
      orientation: "h",
      marker: {
        color: colors[i],
      },
    })) as any;

    return (
      <Plot
        data={[average, ...sc]}
        layout={layout}
        config={{ displayModeBar: false }}
      />
    );
  }
}

type BandChartProps = {
  labels: string[];
  averageValues: number[] | string[];
  individualValues?: number[] | string[];
  phaseNames?: string[];
  // range: number[];
  width: number;
  height?: number;
};

export function BandChart(props: BandChartProps) {
  // Define layout
  const layout = {
    barmode: "stack",
    width: containerWidth[props.width],
    height: props.height ?? containerHeightBandChart,
    margin: { t: 5, l: 10, r: 10, b: 100 },
    legend: {
      orientation: "h",
      yanchor: "top",
      y: -0.2,
      traceorder: "normal",
    },
    font,
    xaxis: {
      dtick: 10,
    },
  } as any;
  const averageValues = props.averageValues?.map((val) => Number(val));
  const individualValues = props.individualValues?.map((val) => Number(val));

  // Statistics
  const average = frequency.toPercentile(averageValues).map((score, i) => ({
    type: "bar",
    y: [""],
    x: [score],
    name: props.labels[i],
    orientation: "h",
    marker: {
      color: colorsAverage[i],
    },
    text:
      score > 17
        ? props.labels[i]
        : score > 12
        ? props.labels[i].slice(0, 5) +
          "<br>" +
          props.labels[i].slice(5, 10) +
          "<br>" +
          props.labels[i].slice(10, 15)
        : "",
  })) as any;

  if (individualValues === undefined) {
    // plot average line only
    return (
      <Plot data={average} layout={layout} config={{ displayModeBar: false }} />
    );
  } else {
    // plot individual score with average line
    const sc = individualValues?.map((score, i) => ({
      y: [""],
      x: [frequency.getPositionInPercentile(score, averageValues)],
      name: props.phaseNames?.[i],
      mode: "markers",
      marker: { size: 12, symbol: "star", color: colors[i] },
    })) as any;

    return (
      <Plot
        data={[...average, ...sc]}
        layout={layout}
        config={{ displayModeBar: false }}
      />
    );
  }
}
