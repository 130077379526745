import { Phase, Individual } from "./entity";

export function phaseToIndividual(data: Phase[], sid: string): Individual {
  // convine two phases and move timestamp

  // ###### for sample
  // ###### data[0] 入学時・data[1] 卒業時
  // const individual = new Individual(
  //   Individual.withUid(uid(data), [
  //     timestamp(data[0]),
  //     ...range(data[0], 0, 7, asItIs),
  //     removeCharacter(data[0], 8),
  //     ...range(data[0], 9, 28, asItIs),
  //     timestamp(data[1]),
  //     ...range(data[1], 0, 7, asItIs),
  //     removeCharacter(data[1], 8),
  //     ...range(data[1], 9, 28, asItIs),
  //   ])
  // );

  // ##### for kakogawahigashi
  if (sid === "kakogawahigashi") {
    return new Individual(
      Individual.withUid(uid(data), [
        ...range(data[0], 0, 67, asItIs),
        plusMinusAverage(data[0], 33, 34, 7),
        plusMinusAverage(data[0], 36, 35, 7),
        plusMinusAverage(data[0], 37, 38, 7),
        plusMinusAverage(data[0], 39, 40, 7),
        plusMinusAverage(data[0], 41, 42, 7),
        将来についての見通し(data[0], 64, 66),
      ])
    );
  } else if (sid === "johoku") {
    return new Individual(
      Individual.withUid(uid(data), [
        ...range(data[0], 0, 89, asItIs),
        plusMinusAverage(data[0], 50, 51, 7),
        plusMinusAverage(data[0], 52, 53, 7),
        plusMinusAverage(data[0], 54, 55, 7),
        plusMinusAverage(data[0], 56, 57, 7),
        plusMinusAverage(data[0], 58, 59, 7),
        将来についての見通し(data[0], 86, 87),
      ])
    );
  } else {
    throw Error("存在しないsidです。");
  }
}

// get uid from eather phases
const uid = (data: Phase[]) =>
  data.reduce((uid, next) => next?.getUid() ?? uid, "");

// const timestamp = (phase: Phase) => phase?.getTimestamp() ?? "";

const asItIs = (phase: Phase, id: number) => phase?.getField(id) ?? "";

// const removeCharacter = (phase: Phase, id: number) => {
//   const str = asItIs(phase, id);
//   const matched = str.match(/^[0-9]/);
//   if (matched !== null) return matched[0];
//   else return "";
// };

const range = (
  phase: Phase,
  from: number,
  to: number,
  func: (phase: Phase, id: number) => string
) => [...Array(to - from + 1)].map((_, i) => func(phase, i + from));

// パーソナリティ
const plusMinusAverage = (
  phase: Phase,
  plus: number,
  minus: number,
  max: number = 7
) => {
  return String(
    (Number(phase.getField(plus)) - Number(phase.getField(minus)) + max + 1) / 2
  );
};

// 将来についての見通し
const 将来についての見通し = (phase: Phase, Q1: number, Q2: number) =>
  phase.getField(Q1) === "2" ? "1" : String(5 - Number(phase.getField(Q2)));
