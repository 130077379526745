import React, { useEffect } from "react";
import { Row, Button } from "react-bootstrap";

const CLIENT_ID =
  "443438272125-a77213ciuihrjuki7j5q392ev1o5pno0.apps.googleusercontent.com";
const API_KEY = "AIzaSyCHgT_QC-fyRom45hL2v3XOPwu_Y6ZCbD0";

// Array of API discovery doc URLs for APIs used by the quickstart
const DISCOVERY_DOCS = [
  "https://sheets.googleapis.com/$discovery/rest?version=v4",
];

// Authorization scopes required by the API; multiple scopes can be
// included, separated by spaces.
const SCOPES = "https://www.googleapis.com/auth/spreadsheets";

export class auth {
  private static authorizeButton: HTMLElement | null;
  private static signoutButton: HTMLElement | null;
  private static container: HTMLElement | null;
  private static isSignedIn: boolean = false;
  private static handlers: ((isSignedIn: boolean) => void)[] = [];

  private static client: any;
  private static access_token: any;

  static handleClientLoad() {
    // SpreadSheet API へアクセスするためのAPIの初期化
    window.gapi.load("client", () => {
      window.gapi.client
        .init({
          // NOTE: OAuth2 'scope' and 'client_id' parameters have moved to initTokenClient().
        })
        .then(function () {
          // Load the Calendar API discovery document.
          window.gapi.client.load(DISCOVERY_DOCS[0], "v4");
        });
    });

    // Google Identity Serviceの初期化
    //@ts-ignore
    auth.client = window.google.accounts.oauth2.initTokenClient({
      client_id: CLIENT_ID,
      scope: SCOPES,
      api_key: API_KEY,
      callback: "",
    });
  }

  static updateSigninStatus(isSignedIn: boolean) {
    auth.isSignedIn = isSignedIn;

    // Call custamize handlers
    auth.handlers.forEach((handler) => handler(isSignedIn));

    if (auth.authorizeButton == null || auth.signoutButton == null) return;

    if (isSignedIn) {
      auth.authorizeButton && (auth.authorizeButton.style.display = "none");
      auth.signoutButton && (auth.signoutButton.style.display = "block");
      auth.container && (auth.container.style.display = "");
    } else {
      auth.authorizeButton && (auth.authorizeButton.style.display = "block");
      auth.signoutButton && (auth.signoutButton.style.display = "none");
      auth.container && (auth.container.style.display = "none");
    }
  }

  static addSignInStatusHandler(handler: (isSignedIn: boolean) => void) {
    auth.handlers.push(handler);
    handler(auth.getSignedInStatus());
  }

  static getSignedInStatus() {
    return auth.isSignedIn;
  }

  static handleAuthClick(event: any) {
    auth.client.callback = async (resp: any) => {
      if (resp.error !== undefined) {
        throw resp;
      }

      auth.updateSigninStatus(true);
    };
    if (window.gapi.client.getToken() === null) {
      // Prompt the user to select a Google Account and asked for consent to share their data
      // when establishing a new session.
      auth.client.requestAccessToken({ prompt: "consent" });
    } else {
      // Skip display of account chooser and consent dialog for an existing session.
      auth.client.requestAccessToken({ prompt: "" });
    }
  }

  static handleSignoutClick(event: any) {
    const cred = window.gapi.client.getToken();
    if (cred !== null) {
      //@ts-ignore
      window.google.accounts.oauth2.revoke(cred.access_token, () => {});
      window.gapi.client.setToken(null);
      auth.updateSigninStatus(false);
    }
  }

  static setButtons(
    authorizeButtonId: string,
    signoutButtonId: string,
    containerId: string
  ) {
    auth.authorizeButton = document.getElementById(authorizeButtonId);
    auth.signoutButton = document.getElementById(signoutButtonId);
    auth.container = document.getElementById(containerId);

    if (auth.authorizeButton == null || auth.signoutButton == null) return;
    auth.authorizeButton.onclick = auth.handleAuthClick;
    auth.signoutButton.onclick = auth.handleSignoutClick;
  }
}

type Props = {
  containerId: string;
};

export function AuthButton(props: Props) {
  useEffect(() => {
    auth.handleClientLoad();
    auth.setButtons("authorize_button", "signout_button", props.containerId);
  }, [props.containerId]);

  return (
    <Row className="mt-5 justify-content-md-center">
      <Button
        id="authorize_button"
        variant="primary"
        style={{ display: "block" }}
        className="col-12 col-md-2"
      >
        サインイン
      </Button>
      <Button
        id="signout_button"
        variant="secondary"
        style={{ display: "none" }}
        className="col-12 col-md-2"
      >
        サインアウト
      </Button>
    </Row>
  );
}
