import { IndividualData, Statistic } from "./entity";
import { frequency } from "./frequency";

export function individualToStatistic(
  data: IndividualData,
  sid: string
): Statistic {
  // ##### for test
  // return new Statistic([
  //   "",
  //   joinWithBR(data.getColumn(1)),
  //   joinWithBR(data.getColumn(2)),
  //   ...range(data, 3, 8, average),
  //   frequency.summarize(data.getColumn(9), 7),
  //   ...range(data, 10, 29, average),
  //   //
  //   "",
  //   joinWithBR(data.getColumn(31)),
  //   joinWithBR(data.getColumn(32)),
  //   ...range(data, 33, 38, average),
  //   frequency.summarize(data.getColumn(39), 7),
  //   ...range(data, 40, 59, average),
  // ]);

  // #### for kakogawahigashi
  if (sid === "kakogawahigashi") {
    return new Statistic([
      "", // 学科
      ...range(data, 1, 12, average),
      frequency.summarize(data.getColumn(13), 7),
      frequency.summarize(data.getColumn(14), 4),
      frequency.summarize(data.getColumn(15), 5),
      ...range(data, 16, 63, average),
      frequency.summarize(data.getColumn(64), 2),
      joinWithBR(data.getColumn(65)),
      frequency.summarize(data.getColumn(66), 3),
      joinWithBR(data.getColumn(67)),
      ...range(data, 68, 72, average),
      frequency.summarize(data.getColumn(73), 4),
    ]);
  } else if (sid === "johoku") {
    return new Statistic([
      ...range(data, 0, 10, average),
      frequency.summarize(data.getColumn(11), 7),
      frequency.summarize(data.getColumn(12), 7),
      frequency.summarize(data.getColumn(13), 7),
      frequency.summarize(data.getColumn(14), 4),
      frequency.summarize(data.getColumn(15), 5),
      ...range(data, 16, 85, average),
      frequency.summarize(data.getColumn(86), 2),
      frequency.summarize(data.getColumn(87), 3),
      frequency.summarize(data.getColumn(88), 2),
      joinWithBR(data.getColumn(89)),
      ...range(data, 90, 94, average),
      frequency.summarize(data.getColumn(95), 4),
    ]);
  } else {
    throw Error("存在しないsidです。");
  }
}

const toNumber = (column: string[]) => column.map((item) => Number(item));

const sum = (sum: number, item: number) => sum + item;

// Join data with "<br>"
const joinWithBR = (column: string[]) => column.join("\n");

// Average data at the id
const average = (column: string[]) =>
  String(toNumber(column).reduce(sum) / column.length);

// Apply function at the range of id
const range = (
  data: IndividualData,
  from: number,
  to: number,
  func: (column: string[]) => string
) => [...Array(to - from + 1)].map((_, i) => func(data.getColumn(i + from)));
