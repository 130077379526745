import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";

import { SpreadSheetInterface } from "./components/spreadsheet";
import { useMatch } from "react-router-dom";
import MenuView from "./views/MenuView";
import IndividualView from "./views/IndividualView";
import StatisticView from "./views/StatisticView";
import { AuthButton } from "./components/auth";
import { setting } from "./app-setting";

import { Mode } from "./domain/Mode";

export default function Main() {
  const [mode, setMode] = useState("None" as Mode);
  const [sheet, setSheet] = useState(
    undefined as SpreadSheetInterface | undefined
  );

  const match = useMatch("/:shoolname");

  useEffect(() => {
    const schoolName =
      typeof match?.params.shoolname === "undefined"
        ? "kakogawahigashi"
        : match?.params.shoolname;

    setSheet(new SpreadSheetInterface(schoolName, "", setting));
  }, [match?.params.shoolname]);

  const changeMode = (mode: Mode) => {
    setMode(mode);
  };

  return (
    <Container>
      <h1>IR-Tool</h1>
      <div id="onlySignedIn" style={{ display: "none" }}>
        {typeof sheet !== "undefined" && (
          <>
            <MenuView sheet={sheet} onChangeMode={changeMode} />
            {mode === "Individual" ? (
              <IndividualView sheet={sheet} />
            ) : mode === "Statistics" ? (
              <StatisticView sheet={sheet} />
            ) : (
              <></>
            )}
          </>
        )}
      </div>
      <AuthButton containerId="onlySignedIn" />
    </Container>
  );
}
