import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { auth } from "../components/auth";
import IndividualLayout from "../components/IndividualLayout";
import { SpreadSheetInterface } from "../components/spreadsheet";
import { Individual, IndividualData, Statistic } from "../domain/entity";
import { IdSelector } from "../components/idSelector";

type Props = {
  sheet: SpreadSheetInterface;
};

export default function IndividualView({ sheet }: Props) {
  const sid = sheet.getSid();
  const [individualData, setIndividualData] = useState(new IndividualData([]));
  const [individual, setIndividual] = useState(new Individual([]));
  const [statistic, setStatistic] = useState(new Statistic([]));
  const [isDataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    auth.addSignInStatusHandler(async (signIn: boolean) => {
      if (signIn) {
        try {
          const response = await sheet.loadIndividualData();
          setIndividualData(response);
          setStatistic(await sheet.getStatisticData());
          setDataLoaded(true);
        } catch (e) {
          alert(`Googleスプレッドシート読込中にエラーが発生しました: ${e}`);
          window.history.back();
        }
      }
    });
  }, [sheet]);

  const onChange = (uid: string) => {
    const individual = individualData.getIndiviual(uid);
    individual && setIndividual(individual);
  };

  if (isDataLoaded === false) return <>Loading...</>;
  return (
    <Container>
      <div id="onlySignedIn">
        <IdSelector uids={individualData.getUids()} onChange={onChange} />
        <IndividualLayout
          individual={individual}
          statistic={statistic}
          phaseNames={sheet.getPhaseNames()}
          sid={sid}
        />
      </div>
    </Container>
  );
}
