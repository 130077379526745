export const setting = {
  schools: [
    {
      sid: "",
      name: "",
      groups: [
        {
          gid: "",
          phase: [
            {
              name: "入学時",
              sheetId: "1BpIdlMug3LHRpLg717iaD6Eg_QHM5dKU-vMsPC_97dM",
            },
            {
              name: "卒業時",
              sheetId: "1PbFaN8v0M039RL6x4BPnLs5IQKrjvH0ZAxSDprCpjZc",
            },
          ],
          resultSheetId: "1E2xdHlVCYG571t9yt0-8OhudYo66YJRATe_AwkdHs0s",
        },
      ],
    },
    {
      sid: "kakogawahigashi",
      name: "加古川東高校",
      groups: [
        {
          gid: "",
          phase: [
            {
              name: "入学時",
              sheetId: "1Hm5zaganV9GpY1dhIPO3IClwrL1smlZ5rTvatrkZS40",
            },
          ],
          resultSheetId: "1wb0L2J8ajWqbtqLCVC9m6d-IhxRinFVKwkhRpzelGuw",
        },
      ],
    },
    {
      sid: "johoku",
      name: "城北高等学校",
      groups: [
        {
          gid: "",
          phase: [
            {
              name: "入学時",
              sheetId: "1N13cgqhhJAnGu7JHc0S3u3bcQ-hHQCGXXnEs4n1REeU",
            },
          ],
          resultSheetId: "175lawdkspsNQKUbcnax3gupmfT6y7NuvFoLuJ3l9OQA",
        },
      ],
    },
  ],
};

export type Setting = {
  schools: {
    sid: string;
    name: string;
    groups: {
      gid: string;
      phase: {
        name: string;
        sheetId: string;
      }[];
      resultSheetId: string;
    }[];
  }[];
};
