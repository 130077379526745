import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import { auth } from "../components/auth";
import StatisticLayout from "../components/StatisticLayout";
import { SpreadSheetInterface } from "../components/spreadsheet";
import { Statistic } from "../domain/entity";

type Props = {
  sheet: SpreadSheetInterface;
};

export default function StatisticView({ sheet }: Props) {
  const sid = sheet.getSid();
  const [statistic, setStatistic] = useState(new Statistic([]));
  const [isDataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {
    auth.addSignInStatusHandler(async (signIn: boolean) => {
      if (signIn) {
        try {
          setStatistic(await sheet.getStatisticData());
          setDataLoaded(true);
        } catch (e) {
          alert(`Googleスプレッドシート読込中にエラーが発生しました: ${e}`);
          window.history.back();
        }
      }
    });
  }, [sheet]);

  if (isDataLoaded === false) return <>Loading...</>;
  return (
    <Container>
      <StatisticLayout
        statistic={statistic}
        phaseNames={sheet.getPhaseNames()}
        sid={sid}
      />{" "}
    </Container>
  );
}
