export class Phase {
  private rawdata: string[];

  static withUid(uid: string, data: string[]) {
    return [uid, ...data];
  }

  constructor(rawdata: string[]) {
    this.rawdata = rawdata;
  }

  getUid() {
    // [timestamp] [agreement] [uid] ...
    return this.rawdata[2];
  }

  getTimestamp() {
    return this.rawdata[0];
  }

  getAllField() {
    // [timestamp] [agreement] [uid] ...
    return this.rawdata.slice(3);
  }

  getField(id: number) {
    // [timestamp] [agreement] [uid] ...
    return this.rawdata[id + 3];
  }
}

export class PhaseData {
  private phaseData: Phase[];

  constructor(phaseData: Phase[]) {
    this.phaseData = phaseData;
  }

  getAllUids() {
    return this.phaseData.map((phase) => phase.getUid());
  }

  getPhaseByUid(uid: string) {
    return this.phaseData.filter((phase) => phase.getUid() === uid)[0];
  }
}

export class MultiplePhaseData {
  private multiplePhaseData: PhaseData[];

  constructor(multiplePhaseData: PhaseData[]) {
    this.multiplePhaseData = multiplePhaseData;
  }

  getAllUids() {
    const uids_ = this.multiplePhaseData.reduce((ids: string[], phaseData) => {
      const next = phaseData.getAllUids();
      return [...ids, ...next];
    }, []);
    return uids_.filter((ele, pos) => uids_.indexOf(ele) === pos);
  }

  getSummary(
    phaseToIndividualFunc: (data: Phase[], sid: string) => Individual,
    individualToStatisticFunc: (data: IndividualData, sid: string) => Statistic,
    sid: string
  ) {
    // Get all Ids from phase data
    const uids = this.getAllUids();

    // Create individual data
    const individualData = new IndividualData(
      uids.map((uid) => {
        const individualPhase = this.multiplePhaseData.map((phaseData) =>
          phaseData.getPhaseByUid(uid)
        );

        // convert phase data for each user to individual data
        const individual = phaseToIndividualFunc(individualPhase, sid);
        // write to Google Sheets aynchronously
        return individual;
      })
    );

    // Create statistic
    const statistic = individualToStatisticFunc(individualData, sid);

    return { individualData, statistic };
  }
}

export class Individual {
  private rawdata: string[];

  static withUid(uid: string, data: string[]) {
    return [uid, ...data];
  }

  constructor(rawdata: string[]) {
    this.rawdata = rawdata;
  }

  getUid() {
    // [uid] ...
    return this.rawdata[0];
  }

  getAllField() {
    // [uid] ...
    return this.rawdata.slice(1);
  }

  getField(id: number) {
    // [uid] ...
    return this.rawdata[id + 1];
  }

  seField(id: number, item: string) {
    // [uid] ...
    this.rawdata[id + 1] = item;
  }
}

export class IndividualData {
  private individualData: Individual[];

  constructor(data: Individual[]) {
    this.individualData = data.sort((a, b) =>
      a.getUid() < b.getUid() ? -1 : 1
    );
  }

  getAllField(uid: string) {
    return this.getIndiviual(uid)?.getAllField();
  }

  getField(uid: string, id: number) {
    return this.getIndiviual(uid)?.getField(id);
  }

  getUids() {
    return this.individualData.map((item) => item.getUid());
  }

  getColumn(id: number) {
    return this.individualData.map((item) => item.getField(id));
  }

  getIndiviual(uid: string) {
    const matched = this.individualData.filter((item) => item.getUid() === uid);
    if (matched.length > 0) return matched[0];
    else return undefined;
  }

  getLength() {
    return this.individualData.length;
  }

  getUidsAndAllField() {
    return {
      uids: this.getUids(),
      allField: this.individualData.map((item) => item.getAllField()),
    };
  }
}

export class Statistic {
  private rawdata: string[];

  constructor(data: string[]) {
    this.rawdata = data;
  }

  getAllField() {
    // ...
    return this.rawdata.slice(0);
  }

  getField(id: number) {
    // ...
    return this.rawdata[id];
  }

  seField(id: number, item: string) {
    // ...
    this.rawdata[id] = item;
  }
}

export type schoolId = string;
export type groupId = string;
export type phaseId = number;
export type userId = string;
