import React, { useState } from "react";
import { Button, Spinner } from "react-bootstrap";
import { phaseToIndividual } from "../domain/phaseToIndividualConverter";
import { individualToStatistic } from "../domain/individualToStatisticConverter";
import { SpreadSheetInterface } from "../components/spreadsheet";

export function ConvertButton({
  sheet,
  afterConvert,
}: {
  sheet: SpreadSheetInterface;
  afterConvert: () => void;
}) {
  const [onProcessing, setOnProcessing] = useState(false);
  async function convert() {
    try {
      setOnProcessing(true);
      try {
        await sheet.initPhaseData();
        const multiplePhaseData = await sheet.getAllPhaseData();

        const { individualData, statistic } =
          await multiplePhaseData.getSummary(
            phaseToIndividual,
            individualToStatistic,
            sheet.getSid()
          );

        await Promise.all([
          (async () => {
            await sheet.clearIndividualSheet();
            await sheet.appendIndividualData(individualData);
          })(),
          (async () => {
            await sheet.clearStatisticSheet();
            await sheet.appendStatisticData(statistic);
          })(),
        ]);
        afterConvert();
      } catch (e) {
        alert(`Googleスプレッドシート読込中にエラーが発生しました: ${e}`);
      }

      setOnProcessing(false);
    } catch (err) {
      console.error(err);
    }
  }
  return (
    <Button onClick={convert} variant="success" disabled={onProcessing}>
      <Spinner
        as="span"
        animation="border"
        size="sm"
        role="status"
        aria-hidden="true"
        style={{ display: onProcessing ? "" : "none" }}
      />
      質問紙から個票・統計データを作成
    </Button>
  );
}
