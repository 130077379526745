import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { SpreadSheetInterface } from "../components/spreadsheet";
import { ConvertButton } from "../components/ConvertButton";
import "../App.css";
import { Mode } from "../domain/Mode";

type Props = {
  sheet: SpreadSheetInterface;
  onChangeMode: (mode: Mode) => void;
};

export default function MenuView({ sheet, onChangeMode }: Props) {
  const phaseSheetUrls = sheet.getPhaseSheetUrls();
  const phaseName = sheet.getPhaseNames();
  const resultSheetUrl = sheet.getResultSheetUrl();

  const onNone = () => {
    onChangeMode("None");
  };

  const onIndividual = () => {
    onChangeMode("Individual");
  };

  const onStatistics = () => {
    onChangeMode("Statistics");
  };

  return (
    <>
      <Row>
        <Col sm={6}>
          <h3>データ作成・表示</h3>
          <Row className="mt-3">
            <Col>
              <Button className="me-3" onClick={onIndividual}>
                個票データを表示
              </Button>
              <Button onClick={onStatistics}>統計データを表示</Button>
            </Col>
          </Row>
        </Col>
        <Col sm={6}>
          <h3>質問紙の回答を表示・編集</h3>
          <ul>
            {phaseSheetUrls.map((url, id) => (
              <li key={phaseName[id]}>
                <a href={url} target="_blank" rel="noreferrer">
                  {phaseName[id]}
                </a>
              </li>
            ))}
            <li>
              <a href={resultSheetUrl} target="_blank" rel="noreferrer">
                個票・統計データを表示
              </a>
            </li>
          </ul>
          <ConvertButton sheet={sheet} afterConvert={onNone} />
        </Col>
      </Row>
    </>
  );
}
