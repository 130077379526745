import React, { useState, useRef } from "react";
import { Form, Button, Row, Col } from "react-bootstrap";

type IdSelectorProps = {
  uids: string[];
  onChange: (uid: string) => void;
};

export function IdSelector({ uids, onChange }: IdSelectorProps) {
  const [uidIndex, setUidIndex] = useState<number | null>(null);
  const uidText = useRef<HTMLInputElement>(null);

  const onText = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (uids.some((uid) => uid === event.target.value)) {
      const idx = uids.indexOf(event.target.value);
      setUidIndex(idx);
      onChange(uids[idx]);
    }
  };

  const onPrev = () => {
    if (uidIndex == null || uidIndex > 0) {
      const idx = uidIndex == null ? uids.length - 1 : uidIndex - 1;
      setUidIndex(idx);
      uidText.current && (uidText.current.value = uids[idx]);
      onChange(uids[idx]);
    }
  };

  const onNext = () => {
    if (uidIndex == null || uidIndex < uids.length - 1) {
      const idx = uidIndex == null ? 0 : uidIndex + 1;
      setUidIndex(idx);
      uidText.current && (uidText.current.value = uids[idx]);
      onChange(uids[idx]);
    }
  };

  return (
    <form className="form-inline mt-5">
      <Row>
        <Col sm={4} xl={2}>
          <Form.Control
            id="uid"
            ref={uidText}
            placeholder="学籍番号"
            onChange={onText}
          />
        </Col>
        <Col>
          <Button onClick={onPrev}>＜</Button>
          <Button onClick={onNext}>＞</Button>
        </Col>
      </Row>
    </form>
  );
}
